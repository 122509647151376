<template>
  <Transition name="collapse">
    <slot class="will-change-[opacity,transform,backdrop-filter]" />
  </Transition>
</template>

<style lang="sass">
.collapse-enter-active, .collapse-leave-active
  transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1)
  overflow: hidden

.collapse-enter-from, .collapse-leave-to
  opacity: 0
  transform: translateY(-10%)
  backdrop-filter: blur(0)

.collapse-enter-to, .collapse-leave-from
  opacity: 1
  transform: translateY(0)
  backdrop-filter: blur(8px)
</style>
